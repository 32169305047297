<template>
  <div
    class="modal fade"
    id="loginModal"
    tabindex="-1"
    aria-labelledby="loginModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginModalLabel">Log in with Google</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <button
            @click="loginWithGoogle"
            class="btn btn-outline-info w-100 m-2"
          >
            <i class="fab fa-google"></i>
            Login with Google
          </button>
          <!-- Add more buttons or a form for other login methods as needed -->
          <!-- Login with Facebook -->
          <!-- <button
            @click="loginWithFacebook"
            class="btn btn-outline-info w-100 m-2"
          >
            <i class="fab fa-facebook"></i>
            Login with Facebook
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginModal',
  methods: {
    loginWithGoogle() {
      const preLoginUrl =
        window.location.pathname + window.location.search + window.location.hash
      localStorage.setItem('preLoginUrl', preLoginUrl)
      window.location.href = '/api/auth/google'
    },
    // Add methods for other authentication providers if needed
    loginWithFacebook() {
      const preLoginUrl =
        window.location.pathname + window.location.search + window.location.hash
      localStorage.setItem('preLoginUrl', preLoginUrl)
      window.location.href = '/api/auth/facebook'
    },
  },
}
</script>

<template>
  <!-- Header-->
  <header class="header">
    <!-- Top bar -->
    <div class="py-2 bg-dark text-white">
      <div class="container py-1">
        <!--Desktop NAV -->
        <div class="row align-items-center">
          <div class="col-lg-8 d-none d-lg-block">
            <ul class="list-inline mb-0 text-md">
              <li class="list-inline-item">
                <router-link class="reset-anchor" to="/"
                  ><i class="fas fa-home me-2"></i> Home</router-link
                >
              </li>
              <li class="list-inline-item">|</li>
              <li class="list-inline-item">
                <div class="dropdown text-md">
                  <a
                    class="reset-anchor dropdown-toggle"
                    id="destinations"
                    href="#"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >Destinations</a
                  >
                  <div
                    class="dropdown-menu dropdown-menu-end mt-3"
                    aria-labelledby="destinations"
                  >
                    <router-link class="dropdown-item text-md" to="/europe"
                      >Europe
                    </router-link>
                    <router-link class="dropdown-item text-md" to="/asia"
                      >Asia</router-link
                    >
                  </div>
                </div>
              </li>
              <li class="list-inline-item">|</li>
              <li class="list-inline-item">
                <router-link class="reset-anchor" to="/coloring-pages"
                  ><i class="fas fa-star purple"></i> Coloring Pages
                </router-link>
              </li>
              <li class="list-inline-item">|</li>
              <li class="list-inline-item">
                <router-link class="reset-anchor" to="/contact"
                  >Contact
                </router-link>
              </li>
              <li class="list-inline-item">|</li>
              <li class="list-inline-item">
                <div class="dropdown text-md">
                  <a
                    class="reset-anchor dropdown-toggle"
                    id="destinations"
                    href="#"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >Travel Tips</a
                  >
                  <div
                    class="dropdown-menu dropdown-menu-end mt-3"
                    aria-labelledby="destinations"
                  >
                    <router-link class="dropdown-item text-md" to="/travel-tips"
                      >General travel tips
                    </router-link>
                    <router-link
                      class="dropdown-item text-md"
                      to="/about-cruises"
                      >Info about Cruises
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-lg-4 d-none d-lg-block text-center">
            <ul class="list-inline mb-0 small">
              <li class="list-inline-item">
                <a
                  class="reset-anchor"
                  href="https://www.facebook.com/ioana.ciutac/"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><i class="fab fa-facebook-f"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  class="reset-anchor"
                  href="https://www.instagram.com/traveltrailadventures/"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <!-- <li class="list-inline-item">
                <a
                  class="reset-anchor"
                  href="https://www.youtube.com/@TravelTrailAdventures"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><i class="fab fa-youtube"></i
                ></a>
              </li> -->
              <li class="list-inline-item">
                <a
                  class="reset-anchor"
                  href="https://www.tripadvisor.com/Profile/traveltrailblog"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><i class="fab fa-tripadvisor"></i
                ></a>
              </li>
              <li class="list-inline-item">
                <a
                  class="reset-anchor"
                  href="https://www.pinterest.com/traveltrailadventures/"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><i class="fab fa-pinterest"></i
                ></a>
              </li>
              <li class="list-inline-item" v-if="withLogin">
                <button
                  v-if="!isLoggedIn"
                  class="btn btn-sm btn-primary ms-5"
                  data-bs-toggle="modal"
                  data-bs-target="#loginModal"
                >
                  Login
                </button>
                <button
                  v-if="isLoggedIn"
                  class="btn btn-sm btn-primary ms-5"
                  @click="userLogout"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!--Mobile NAV -->
        <div class="row align-items-center justify-content-between">
          <div class="col-md-2 col-3 d-block d-lg-none">
            <div class="logo text-start align-self-center">
              <a href="/">
                <img
                  src="/img/logo.webp"
                  alt="Travel Trail Logo"
                  class="img-fluid img-border"
                />
              </a>
            </div>
          </div>
          <div class="offset-4 col-5 d-block d-lg-none">
            <div class="d-flex justify-content-end">
              <nav class="navbar navbar-light ms-auto">
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarToggleExternalContent"
                  aria-controls="navbarToggleExternalContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i class="fas fa-bars"></i>
                </button>
              </nav>
            </div>
            <div class="collapse" id="navbarToggleExternalContent">
              <ul class="collapsed-menu">
                <li>
                  <a href="/"><i class="fas fa-home purple pr-2"></i> Home</a>
                </li>
                <li>
                  <a href="/europe"
                    ><i class="fas fa-globe-asia purple pr-2"></i>
                    Destinations > Europe
                  </a>
                </li>
                <li>
                  <a href="/asia"
                    ><i class="fas fa-globe-asia purple pr-2"></i> Destinations
                    > Asia</a
                  >
                </li>
                <li>
                  <i class="fas fa-star purple pr-2"></i>
                  <a href="/coloring-pages">Coloring Pages</a>
                </li>
                <li>
                  <a href="/contact"
                    ><i class="fas fa-envelope purple pr-2"></i> Contact</a
                  >
                </li>
                <li>
                  <a href="/travel-tips"
                    ><i class="fas fa-lightbulb purple pr-2"></i> Travel Tips</a
                  >
                </li>
                <li>
                  <a href="/about-cruises"
                    ><i class="fas fa-anchor purple pr-2"></i> About Cruises
                  </a>
                </li>
                <li v-if="withLogin">
                  <a
                    v-if="!isLoggedIn"
                    data-bs-toggle="modal"
                    data-bs-target="#loginModal"
                    role="button"
                    href="#"
                  >
                    <i class="fas fa-user purple pr-2"></i>
                    Login
                  </a>
                  <a
                    v-if="isLoggedIn"
                    role="button"
                    href="#"
                    @click="userLogout"
                  >
                    <i class="fas fa-user purple pr-2"></i>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="news-section text-center">
      <div class="news-description">
        <i class="fas fa-star pr-2"></i>
        <strong>News!</strong> Free Coloring Pages Available for Romania! Don't
        miss the chance to download
        <a href="/coloring-pages" class="orange">Free Travel Coloring Pages</a>
        and explore the beauty of <a href="/romania" class="orange">Romania</a>!
      </div>
    </div>
    <!-- Navbar with Logo Desktop -->
    <nav
      class="navbar navbar-expand-lg navbar-light bg-white d-lg-block d-none"
    >
      <div class="container text-center">
        <a class="navbar-brand mx-auto" href="/">
          <img
            src="/img/logo.webp"
            alt="Travel Trail Logo"
            class="mb-2 img-fluid"
          />
        </a>
      </div>
    </nav>
  </header>
  <LoginModal />
</template>
<script>
import LoginModal from "./LoginModal.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "HeaderComponent",
  components: { LoginModal },
  computed: {
    ...mapState(["isLoggedIn"]),
    withLogin() {
      // Check if the query parameter 'withLogin' exists and if it is 'true'
      // return this.$route.query.withLogin === 'true'
      return true;
    },
  },
  methods: {
    async userLogout() {
      try {
        await fetch("/api/auth/logout", { method: "GET" });
        this.logout();
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    ...mapActions(["logout"]),
  },
};
</script>
<style scoped>
.navbar-light .navbar-toggler {
  color: #fff;
}
.navbar-light .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.fa-bars {
  font-size: 2rem;
}
.collapsed-menu {
  background: white;
  padding: 0;
  border-radius: 5px;
  text-align: left;
  position: absolute;
  right: 2px;
  top: 5.2rem;
  border: solid 1px #ededed;
  width: 100%;
}
.collapsed-menu li {
  list-style: none;
  border-bottom: #e1d6d6 1px solid;
  padding: 0 20px;
}
.collapsed-menu li:nth-child(odd) {
  background-color: #ffffff;
}
.collapsed-menu li:nth-child(even) {
  background-color: #f4f1f1;
}
.collapsed-menu span {
  color: #1d8e9f;
  font-weight: 700;
}
.fa-globe-asia {
  font-size: unset;
}
.news-section {
  background-color: #1d8e9f;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
}
.news-description {
  padding: 5px;
  line-height: 1.4;
}
.orange {
  color: orange;
}
</style>

import { createStore } from 'vuex'
import axios from 'axios'

const localStorageOperations = {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getItem(key, defaultValue) {
    return JSON.parse(localStorage.getItem(key)) || defaultValue
  },
  removeItem(key) {
    localStorage.removeItem(key)
  },
}

const api = {
  async fetchUserDetails() {
    try {
      const response = await axios.get('/api/user/details')
      return response.data
    } catch (error) {
      console.error('Error fetching user details:', error)
      throw error
    }
  },
}

export default createStore({
  state: {
    isLoggedIn: localStorageOperations.getItem('isLoggedIn', false),
    user: localStorageOperations.getItem('userDetails', null),
  },
  mutations: {
    setLoggedIn(state, status) {
      state.isLoggedIn = status
      localStorageOperations.setItem('isLoggedIn', status)
    },
    setUserDetails(state, userDetails) {
      state.user = userDetails
      localStorageOperations.setItem('userDetails', userDetails)
    },
  },
  actions: {
    login({ commit }) {
      commit('setLoggedIn', true)
    },
    logout({ commit }) {
      commit('setLoggedIn', false)
      commit('setUserDetails', null)
      localStorageOperations.removeItem('userDetails')
    },
    async fetchUserDetails({ commit }) {
      const userDetails = await api.fetchUserDetails()
      commit('setUserDetails', userDetails)
      return userDetails
    },
  },
  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
  },
})

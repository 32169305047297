<template>
  <div>
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-PP8X5DZZ"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
export default {
  name: "GtmScript",
  mounted() {
    // Dynamically load Google Tag Manager script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-PP8X5DZZ`;
    document.head.appendChild(script);

    // Push the dataLayer event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
  },
};
</script>

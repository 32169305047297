<template>
  <footer class="bg-dark py-4">
    <div class="container-fluid">
      <div class="row py-2 gy-2">
        <div class="col-lg-6 text-center text-lg-start">
          <p class="small text-white text-uppercase mb-0">
            <router-link to="/privacy-policy"
              >Privacy Policy and Terms of Service</router-link
            >
            | <router-link to="/about">About Me</router-link> |
            <router-link to="/collaborations">Collaborations</router-link>
            <!-- <router-link to="/affiliates">Affiliates</router-link> -->
          </p>
        </div>
        <div class="col-lg-6 text-center text-lg-end">
          <ul class="list-inline text-white small mt-lg-0 mt-4 mb-4">
            <li class="list-inline-item">
              <a
                class="reset-anchor"
                href="https://www.facebook.com/ioana.ciutac/"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                class="reset-anchor"
                href="https://www.instagram.com/traveltrailadventures/"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="fab fa-instagram"></i
              ></a>
            </li>
            <!-- <li class="list-inline-item">
              <a
                class="reset-anchor"
                href="https://www.youtube.com/@TravelTrailAdventures"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="fab fa-youtube"></i
              ></a>
            </li> -->
            <li class="list-inline-item">
              <a
                class="reset-anchor"
                href="https://www.tripadvisor.com/Profile/traveltrailblog"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="fab fa-tripadvisor"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                class="reset-anchor"
                href="https://www.pinterest.com/traveltrailadventures/"
                target="_blank"
                rel="noopener noreferrer"
                ><i class="fab fa-pinterest"></i
              ></a>
            </li>
          </ul>
          <p class="small text-white text-uppercase mb-0">
            Template designed by
            <a href="https://bootstrapious.com/p/bootstrap-travel-blog-template"
              >Bootstrapious</a
            >.
          </p>
          <p class="small text-white text-uppercase mb-0">
            &copy; copyright 2024 - all rights reserved
          </p>
          <!-- If you want to remove the backlink, please purchase the Attribution-Free License. See details in readme.txt or license.txt. Thanks!-->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default { name: "FooterComponent" };
</script>

<template>
  <div
    v-if="!consentGiven"
    class="cookies-consent-popup d-flex justify-content-center"
  >
    <div class="row">
      <div class="col-12 col-lg-10 text-center text-lg-start align-self-center">
        This site uses cookies for a better browsing experience. By continuing,
        you agree to our cookie use. Read more in
        <a href="/privacy-policy">Privacy Policy and Terms of Service</a> page.
      </div>
      <div
        class="col-12 col-lg-2 text-center text-lg-start mt-2 mt-lg-0 align-self-center"
      >
        <button
          class="btn btn-sm btn-primary mb-2 mb-lg-0 img-border"
          @click="giveConsent"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";

export default {
  name: "CookiesConsentPopup",
  data() {
    return {
      consentGiven: VueCookies.get("cookies-consent"),
    };
  },
  methods: {
    giveConsent() {
      this.consentGiven = true;
      VueCookies.set("cookies-consent", true, "7d"); // Set for 7 days
    },
  },
};
</script>

<style scoped>
.cookies-consent-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3f3d3d;
  color: #fff;
  padding: 8px 25px;
  text-align: center;
  z-index: 1000;
  font-size: 0.9em;
  line-height: 1.2;
  text-align: left;
}
</style>

<template>
  <div></div>
</template>
<script>
export default {
  setup() {
    // Add the Google Analytics script dynamically
    const gtagScriptSrc =
      "https://www.googletagmanager.com/gtag/js?id=G-8BJR1KD9E9";

    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = gtagScriptSrc;

    const existingScript = document.querySelector(
      `script[src="${gtagScriptSrc}"]`
    );

    if (!existingScript) {
      document.head.appendChild(script);
    }

    // Configure Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-8BJR1KD9E9");

    // Add preconnect or dns-prefetch hint for Google Tag Manager
    const preconnectLink = document.createElement("link");
    preconnectLink.rel = "preconnect";
    preconnectLink.href = "https://www.googletagmanager.com";
    document.head.appendChild(preconnectLink);

    // Return an empty object to resolve the Vue warning
    return {};
  },
};
</script>

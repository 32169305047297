<template>
  <div id="app">
    <cookies-consent-popup />
    <header-component />
    <div class="router-page">
      <router-view></router-view>
    </div>
    <footer-component />
    <google-analytics />
    <gtm-script />
  </div>
</template>

<script>
import HeaderComponent from './components/templates/HeaderComponent'
import FooterComponent from './components/templates/FooterComponent'
import GoogleAnalytics from './components/templates/GoogleAnalytics'
import GtmScript from './components/templates/GtmScript'
import CookiesConsentPopup from './lib/components/consent/CookiesConsentPopup'
export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent,
    GoogleAnalytics,
    GtmScript,
    CookiesConsentPopup,
  },
}
</script>

<style scoped>
.router-page {
  min-height: calc(100vh - 100px);
}
</style>

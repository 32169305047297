import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: () => import("../components/frontpage/HomePage.vue"),
  },
  {
    path: "/admin",
    name: "AdminPage",
    component: () => import("../lib/components/admin/AdminPage.vue"),
  },
  {
    path: "/login-success",
    name: "LoginSuccess",
    component: () => import("../components/pages/LoginSuccessPage.vue"),
  },
  {
    path: "/login-failure",
    name: "LoginFailure",
    component: () => import("../components/pages/LoginFailurePage.vue"),
  },
  {
    path: "/cyprus/protaras/",
    name: "ProtarasPage",
    component: () => import("../components/articles/ProtarasPage.vue"),
  },
  {
    path: "/cyprus/fasoula-village-limassol/",
    name: "FasoulaPage",
    component: () => import("../components/articles/FasoulaPage.vue"),
  },
  {
    path: "/cyprus/free-museums-in-cyprus/",
    name: "CyprusMuseumsPage",
    component: () => import("../components/articles/CyprusMuseumsPage.vue"),
  },
  {
    path: "/cyprus/kolossi-castle-limassol/",
    name: "KolossiCastlePage",
    component: () => import("../components/articles/KolossiCastlePage.vue"),
  },
  {
    path: "/cyprus/saint-nicolas-of-the-cats-limassol/",
    name: "SaintNicolasofTheCatsPage",
    component: () =>
      import("../components/articles/SaintNicolasofTheCatsPage.vue"),
  },
  {
    path: "/cyprus/limassol/",
    name: "LimassolPage",
    component: () => import("../components/articles/LimassolPage.vue"),
  },
  {
    path: "/cyprus/limassol-wineries/",
    name: "LimassolWineries",
    component: () => import("../components/pages/LimassolWineries.vue"),
  },
  {
    path: "/cyprus/ayia-napa/",
    name: "AgiaNapaPage",
    component: () => import("../components/articles/AgiaNapaPage.vue"),
  },
  {
    path: "/cyprus/paphos/",
    name: "PaphosPage",
    component: () => import("../components/articles/PaphosPage.vue"),
  },
  {
    path: "/cyprus/nicosia/",
    name: "NicosiaPage",
    component: () => import("../components/articles/NicosiaPage.vue"),
  },
  {
    path: "/cyprus/choirokoitia-neolithic-gem-in-larnaca/",
    name: "ChoirokoitiaPage",
    component: () => import("../components/articles/ChoirokoitiaPage.vue"),
  },
  {
    path: "/cyprus/larnaca/",
    name: "LarnacaPage",
    component: () => import("../components/articles/LarnacaPage.vue"),
  },
  {
    path: "/coloring-pages/",
    name: "ColoringBooks",
    component: () => import("../components/pages/ColoringBooks.vue"),
  },
  {
    path: "/cyprus/trodoos/",
    name: "TroodosPage",
    component: () => import("../components/articles/TroodosPage.vue"),
  },
  {
    path: "/cyprus/",
    name: "CyprusPage",
    component: () => import("../components/articles/CyprusPage.vue"),
  },
  {
    path: "/north-cyprus/famagusta/",
    name: "FamagustaPage",
    component: () => import("../components/articles/FamagustaPage.vue"),
  },
  {
    path: "/north-cyprus/kyrenia/",
    name: "KyreniaPage",
    component: () => import("../components/articles/KyreniaPage.vue"),
  },
  {
    path: "/north-cyprus/",
    name: "TrncPage",
    component: () => import("../components/articles/TrncPage.vue"),
  },
  {
    path: "/moldova/chisinau/",
    name: "ChisinauPage",
    component: () => import("../components/articles/ChisinauPage.vue"),
  },
  {
    path: "/moldova/milestii-mici/",
    name: "MilestiiMiciPage",
    component: () => import("../components/articles/MilestiiMiciPage.vue"),
  },
  {
    path: "/moldova/",
    name: "Moldova",
    component: () => import("../components/articles/MoldovaPage.vue"),
  },
  {
    path: "/czech-republic/",
    name: "CzechRepublicPage",
    component: () => import("../components/articles/CzechRepublicPage.vue"),
  },
  {
    path: "/czech-republic/prague",
    name: "PraguePage",
    component: () => import("../components/articles/PraguePage.vue"),
  },
  {
    path: "/france/",
    name: "France",
    component: () => import("../components/articles/FrancePage.vue"),
  },
  {
    path: "/ireland/dublin/",
    name: "DublinPage",
    component: () => import("../components/articles/DublinPage.vue"),
  },
  {
    path: "/ireland/galway/",
    name: "GalwayPage",
    component: () => import("../components/articles/GalwayPage.vue"),
  },
  {
    path: "/ireland/",
    name: "Ireland",
    component: () => import("../components/articles/IrelandPage.vue"),
  },
  {
    path: "/sweden/",
    name: "Sweden",
    component: () => import("../components/articles/SwedenPage.vue"),
  },
  {
    path: "/italy/",
    name: "Italy",
    component: () => import("../components/articles/ItalyPage.vue"),
  },
  {
    path: "/romania/bucharest/",
    name: "BucharestPage",
    component: () => import("../components/articles/BucharestPage.vue"),
  },
  {
    path: "/romania/sibiu/",
    name: "SibiuPage",
    component: () => import("../components/articles/SibiuPage.vue"),
  },
  {
    path: "/romania/dracula-castle/",
    name: "DraculaCastlePage",
    component: () => import("../components/articles/DraculaCastlePage.vue"),
  },
  {
    path: "/romania/",
    name: "Romania",
    component: () => import("../components/articles/RomaniaPage.vue"),
  },
  {
    path: "/hungary/",
    name: "Hungary",
    component: () => import("../components/articles/HungaryPage.vue"),
  },
  {
    path: "/mediterranean-cruise/",
    name: "MediterraneanCruisePage",
    component: () =>
      import("../components/articles/MediterraneanCruisePage.vue"),
  },
  {
    path: "/dubai/",
    name: "Dubai",
    component: () => import("../components/articles/DubaiPage.vue"),
  },
  {
    path: "/austria/",
    name: "AustriaPage",
    component: () => import("../components/articles/AustriaPage.vue"),
  },
  {
    path: "/netherlands/amsterdam",
    name: "AmsterdamPage",
    component: () => import("../components/articles/AmsterdamPage.vue"),
  },
  {
    path: "/netherlands/",
    name: "NetherlandsPage",
    component: () => import("../components/articles/NetherlandsPage.vue"),
  },
  {
    path: "/switzerland/grindelwald/",
    name: "GrindelwaldPage",
    component: () => import("../components/articles/GrindelwaldPage.vue"),
  },
  {
    path: "/switzerland/lugano/",
    name: "LuganoPage",
    component: () => import("../components/articles/LuganoPage.vue"),
  },
  {
    path: "/switzerland/",
    name: "SwitzerlandPage",
    component: () => import("../components/articles/SwitzerlandPage.vue"),
  },
  {
    path: "/belgium/",
    name: "BelgiumPage",
    component: () => import("../components/articles/BelgiumPage.vue"),
  },
  {
    path: "/hong-kong/",
    name: "HongKongPage",
    component: () => import("../components/articles/HongKongPage.vue"),
  },
  {
    path: "/about/",
    name: "AboutPage",
    component: () => import("../components/articles/AboutPage.vue"),
  },
  {
    path: "/collaborations/",
    name: "CollaborationsPage",
    component: () => import("../components/articles/CollaborationsPage.vue"),
  },
  // {
  //   path: "/affiliates/",
  //   name: "AffiliatesPage",
  //   component: () => import("../components/articles/AffiliatesPage.vue"),
  // },
  {
    path: "/contact/",
    name: "ContactMe",
    component: () => import("../components/pages/ContactMe.vue"),
  },
  {
    path: "/travel-tips/",
    name: "TravelTips",
    component: () => import("../components/pages/TravelTips.vue"),
  },
  {
    path: "/tips-for-traveling-with-kids/",
    name: "TipsForTravelingWithKids",
    component: () => import("../components/pages/TipsForTravelingWithKids.vue"),
  },
  {
    path: "/family-travel-safety-tips/",
    name: "FamilySafetyTravelTips",
    component: () => import("../components/pages/FamilySafetyTravelTips.vue"),
  },
  {
    path: "/packing-tips-for-family-travel/",
    name: "PackingTipsForFamilyTravel",
    component: () =>
      import("../components/pages/PackingTipsForFamilyTravel.vue"),
  },
  {
    path: "/family-travel-tips-for-flying/",
    name: "FamilyTravelTipsForFlying",
    component: () =>
      import("../components/pages/FamilyTravelTipsForFlying.vue"),
  },
  {
    path: "/about-cruises/",
    name: "AboutCruises",
    component: () => import("../components/pages/AboutCruises.vue"),
  },
  {
    path: "/rent-a-car/",
    name: "RentaCar",
    component: () => import("../components/pages/RentaCar.vue"),
  },
  {
    path: "/book-a-hotel-vienna/",
    name: "BookaHotelVienna",
    component: () => import("../components/pages/BookaHotelVienna.vue"),
  },
  {
    path: "/book-a-hotel-dubai/",
    name: "BookaHotelDubai",
    component: () => import("../components/pages/BookaHotelDubai.vue"),
  },
  {
    path: "/book-a-hotel-cyprus/",
    name: "BookaHotelCyprus",
    component: () => import("../components/pages/BookaHotelCyprus.vue"),
  },
  {
    path: "/book-a-hotel-bruges/",
    name: "BookaHotelBruges",
    component: () => import("../components/pages/BookaHotelBruges.vue"),
  },
  {
    path: "/spotlight/",
    name: "DaySpotlight",
    component: () => import("../components/pages/DaySpotlight.vue"),
  },
  {
    path: "/europe/",
    name: "DestinationsEurope",
    component: () => import("../components/pages/DestinationsEurope.vue"),
  },
  {
    path: "/asia/",
    name: "DestinationsAsia",
    component: () => import("../components/pages/DestinationsAsia.vue"),
  },
  {
    path: "/best-easter-travel-destinations/",
    name: "BestEasterDestinationsPage",
    component: () =>
      import("../components/articles/BestEasterDestinationsPage.vue"),
  },
  {
    path: "/privacy-policy/",
    name: "PrivacyPolicy",
    component: () => import("../components/pages/PrivacyPolicy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
